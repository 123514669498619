import classnames from 'classnames/bind'
import React, { useRef, Fragment } from 'react'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import { LinkProps } from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, {
  prismicRichTextAsText,
  RichTextBlock,
} from '~/components/RichText'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import useStickyContent from '~/hooks/useStickyContent'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TextProps {
  subtitle?: RichTextBlock[]
  text?: RichTextBlock[]
  link?: InlineCtaProps
}

export interface ImgPros {
  image?: ImageProps
  text?: string
  link?: LinkProps
}

export interface EditoLongImageTextProps {
  className?: string
  inversed?: boolean
  title?: RichTextBlock[]
  texts: TextProps[]
  images?: ImgPros[]
}

const DEFAULT_ALT = 'Edito long image text image'

function EditoLongImageText({
  className,
  title,
  images,
  texts,
  inversed,
}: EditoLongImageTextProps) {
  const textWrapperRef = useRef<HTMLDivElement>(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const { imageTop, contentTop } = useStickyContent(
    textWrapperRef,
    imageWrapperRef,
  )
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-30' })
  const subtitleStyle = useStyle({ textPreset: 'title-10', color: 'gold' })
  const textStyle = useStyle({ textPreset: 'rich-text-variant-3' })
  const imageTextStyle = useStyle({ textPreset: 'text-10-11' })

  return (
    <section
      className={cx(css.EditoLongImageText, className, {
        inversed: !inversed,
      })}>
      <div className={cx(gridStyle, css.content)}>
        <div
          ref={textWrapperRef}
          className={css.texts}
          style={{ top: contentTop }}>
          {title && (
            <RichText className={cx(titleStyle, css.title)} render={title} />
          )}
          {texts?.map((text, index) => {
            const imageLink = images?.[index]?.link
            const linkLabel =
              text?.link?.children ?? prismicRichTextAsText(title)

            return (
              <Fragment key={`textBlock${index}`}>
                {images?.[index]?.image && (
                  <div
                    className={cx(css.mobileImage, { isFirst: index === 0 })}
                    key={`image${index}`}>
                    <WrapperWithLink
                      className={css.wrapperWithLink}
                      {...getAriaLabelProp(linkLabel as string)}
                      href={imageLink?.href as string}>
                      <Ratio ratio={430 / 573}>
                        {(cn) => (
                          <ImageWithPlaceholder
                            className={cn}
                            layout="fill"
                            objectFit="cover"
                            sizesFromBreakpoints={[
                              { breakpoint: 'md', columns: 4 },
                              { columns: 5 },
                            ]}
                            {...images?.[index].image}
                            alt={images?.[index].image?.alt ?? DEFAULT_ALT}
                          />
                        )}
                      </Ratio>
                    </WrapperWithLink>
                    {images?.[index]?.text && (
                      <div className={cx(css.imageText, imageTextStyle)}>
                        {images?.[index].text}
                      </div>
                    )}
                  </div>
                )}
                {(text?.subtitle || text?.text) && (
                  <div
                    className={cx(css.blockText, { hasMargin: index !== 0 })}
                    key={`text${index}`}>
                    {text?.subtitle?.length > 0 && (
                      <RichText
                        className={subtitleStyle}
                        render={text.subtitle}
                      />
                    )}
                    {text?.text && (
                      <RichText
                        className={cx(css.text, textStyle)}
                        render={text.text}
                      />
                    )}
                    {text?.link && (
                      <InlineCta
                        className={css.link}
                        {...text.link}
                        lineProps={{
                          theme: 'gray',
                          initialLineState: 'underlined',
                        }}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
        <div
          ref={imageWrapperRef}
          className={css.images}
          style={{ top: imageTop }}>
          {images?.map((item, index) => {
            const linkLabel =
              texts?.[index]?.link?.children ?? prismicRichTextAsText(title)

            return (
              item?.image && (
                <div className={css.image} key={`image${index}`}>
                  <WrapperWithLink
                    className={css.wrapperWithLink}
                    {...getAriaLabelProp(linkLabel as string)}
                    href={item?.link?.href as string}>
                    <Ratio ratio={430 / 573}>
                      {(cn) => (
                        <ImageWithPlaceholder
                          className={cn}
                          layout="fill"
                          objectFit="cover"
                          sizesFromBreakpoints={[
                            { breakpoint: 'md', columns: 4 },
                            { columns: 5 },
                          ]}
                          {...item.image}
                          alt={item?.image?.alt ?? DEFAULT_ALT}
                        />
                      )}
                    </Ratio>
                  </WrapperWithLink>
                  {item?.text && (
                    <div className={cx(css.imageText, imageTextStyle)}>
                      {item.text}
                    </div>
                  )}
                </div>
              )
            )
          })}
        </div>
      </div>
    </section>
  )
}

EditoLongImageText.defaultProps = {}

export default EditoLongImageText
