import classnames from 'classnames/bind'
import React, { Fragment } from 'react'

import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TextProps {
  subtitle?: RichTextBlock[]
  text?: RichTextBlock[]
  link?: InlineCtaProps
}

export interface EditoLongTextProps {
  className?: string
  title?: RichTextBlock[]
  texts: TextProps[]
}

function EditoLongText({ className, title, texts }: EditoLongTextProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-30' })
  const subtitleStyle = useStyle({ textPreset: 'title-10', color: 'gold' })
  const textStyle = useStyle({ textPreset: 'rich-text-variant-3' })

  return (
    <section className={cx(css.EditoLongText, className)}>
      <div className={cx(gridStyle, css.content)}>
        <div className={css.texts}>
          {title && (
            <RichText className={cx(titleStyle, css.title)} render={title} />
          )}
          {texts?.map((text, index) => {
            return (
              <Fragment key={`textBlock${index}`}>
                {(text?.subtitle || text?.text) && (
                  <div
                    className={cx(css.blockText, { hasMargin: index !== 0 })}
                    key={`text${index}`}>
                    {text?.subtitle?.length > 0 && (
                      <RichText
                        className={subtitleStyle}
                        render={text.subtitle}
                      />
                    )}
                    {text?.text && (
                      <RichText
                        className={cx(css.text, textStyle)}
                        render={text.text}
                      />
                    )}
                    {text?.link && (
                      <InlineCta
                        className={css.link}
                        {...text.link}
                        lineProps={{
                          theme: 'gray',
                          initialLineState: 'underlined',
                        }}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </section>
  )
}

EditoLongText.defaultProps = {}

export default EditoLongText
