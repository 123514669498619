import classnames from 'classnames/bind'
import { LinkProps } from 'next/link'
import React from 'react'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImageDypticProps {
  className?: string
  inversed?: boolean
  leftLink?: LinkProps
  rightLink?: LinkProps
  imageLeft?: ImageProps
  imageRight?: ImageProps
}

const DEFAULT_ALT = 'Image dyptic image'

function ImageDyptic({
  className,
  imageLeft,
  imageRight,
  leftLink,
  rightLink,
  inversed,
}: ImageDypticProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const { href: leftHref } = leftLink ?? {}
  const { href: rightHref } = rightLink ?? {}

  return (
    <section
      className={cx(css.ImageDyptic, className, gridStyle, { inversed })}>
      {imageLeft && (
        <WrapperWithLink className={css.imageLeft} href={leftHref as string}>
          <ImageWithPlaceholder
            {...imageLeft}
            className={cx({ imageLeft: Boolean(!leftHref) })}
            sizesFromBreakpoints={[
              { breakpoint: 'md', columns: 5 },
              { columns: 6 },
            ]}
            alt={imageLeft?.alt ?? DEFAULT_ALT}
          />
        </WrapperWithLink>
      )}
      {imageRight && (
        <WrapperWithLink className={css.imageRight} href={rightHref as string}>
          <ImageWithPlaceholder
            className={cx({ imageRight: Boolean(!rightHref) })}
            {...imageRight}
            sizesFromBreakpoints={[
              { breakpoint: 'md', columns: 4 },
              { columns: 6 },
            ]}
            alt={imageRight?.alt ?? DEFAULT_ALT}
          />
        </WrapperWithLink>
      )}
    </section>
  )
}

ImageDyptic.defaultProps = {}

export default ImageDyptic
