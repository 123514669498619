import classnames from 'classnames/bind'
import React from 'react'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import Ratio from '~/components/Ratio'
import RichTextElement, {
  prismicRichTextAsText,
  RichTextBlock,
} from '~/components/RichText'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImageFullWidthProps {
  className?: string
  landscapeImage?: ImageProps
  portraitImage?: ImageProps
  title?: RichTextBlock[]
  titleLine?: boolean
  link?: InlineCtaProps
  contentPosition?: 'left' | 'right'
}

const DEFAULT_ALT = 'Image full width'

function ImageFullWidth({
  className,
  landscapeImage,
  portraitImage,
  title,
  titleLine,
  link,
  contentPosition,
}: ImageFullWidthProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-36' })
  const titleContent = prismicRichTextAsText(title)
  const { href } = link ?? {}
  const linkProps = getAriaLabelProp(
    (link?.children as string) ?? 'Image with link',
  )

  return (
    <section
      className={cx(className, css.ImageFullWidth, {
        hasBothImages: landscapeImage && portraitImage,
        hasLineDecorator: titleLine,
      })}>
      {landscapeImage && (
        <WrapperWithLink
          className={cx(css.wrapperWithLink, css.landscape)}
          href={href as string}
          {...linkProps}>
          <Ratio
            preset="ratio-1440-860-375-500"
            className={cx(css.ratio, css.landscape)}>
            {(cn) => (
              <ImageWithPlaceholder
                className={cn}
                objectFit="cover"
                layout="fill"
                sizesFromBreakpoints={[
                  { breakpoint: 'md', columns: 12 },
                  { columns: 6 },
                ]}
                {...landscapeImage}
                alt={landscapeImage?.alt ?? DEFAULT_ALT}
              />
            )}
          </Ratio>
        </WrapperWithLink>
      )}
      {portraitImage && (
        <WrapperWithLink
          className={cx(css.wrapperWithLink, css.portrait)}
          href={href as string}
          {...linkProps}>
          <Ratio
            preset="ratio-1440-860-375-500"
            className={cx(css.ratio, css.portrait)}>
            {(cn) => (
              <ImageWithPlaceholder
                className={cn}
                objectFit="cover"
                layout="fill"
                sizesFromBreakpoints={[
                  { breakpoint: 'md', columns: 12 },
                  { columns: 6 },
                ]}
                {...portraitImage}
                alt={portraitImage?.alt ?? DEFAULT_ALT}
              />
            )}
          </Ratio>
        </WrapperWithLink>
      )}
      {(titleContent || link) && (
        <div className={cx(css.textWrapper, gridStyle, contentPosition)}>
          {titleContent && (
            <RichTextElement
              className={cx(titleStyle, css.title, { titleLine })}
              render={title}
            />
          )}
          {link?.children && link?.href && (
            <InlineCta
              className={css.link}
              {...link}
              lineProps={{ theme: 'gold', initialLineState: 'underlined' }}
            />
          )}
        </div>
      )}
    </section>
  )
}

ImageFullWidth.defaultProps = {
  contentPosition: 'left:',
  titleLine: true,
}

export default ImageFullWidth
