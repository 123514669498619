import classnames from 'classnames/bind'
import React from 'react'

import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LargeTextProps {
  className?: string
  text?: RichTextBlock[]
}

function LargeText({ className, text }: LargeTextProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const textStyle = useStyle({ textPreset: 'text-fluid-20-36' })

  return (
    <section className={cx(className, gridStyle, css.LargeText)}>
      {text && <RichText className={cx(textStyle, css.text)} render={text} />}
    </section>
  )
}

LargeText.defaultProps = {}

export default LargeText
